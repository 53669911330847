import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { AiOutlineTeam, AiOutlineAppstoreAdd } from 'react-icons/ai'; // For icons
import { motion } from 'framer-motion'; // For animation
import { FaLaptopCode } from 'react-icons/fa'; // For a tech icon

const AboutUs = () => {
    return (
        <Container
            maxWidth="md"
            sx={{
                textAlign: 'center',
                marginTop: '0rem',
                marginBottom: '3rem',
                padding: '2rem',
                backgroundColor: '#111',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            }}
        >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <Typography variant="h3" gutterBottom sx={{ color: '#fff', fontWeight: 700 }}>
                    About Us
                </Typography>

                <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                        <Typography variant="body1" color="white">
                            We empower SMB businesses with cutting-edge software solutions. Our expertise lies in delivering innovative, scalable, and customized applications that drive growth and efficiency.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="white">
                            At CGOATS, we provide top-notch software solutions tailored for your business needs. Our dedicated team of professionals ensures that we leverage the latest technologies and best practices to deliver exceptional results.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="white">
                            We believe in creating value for our clients by understanding their challenges and crafting innovative solutions that drive success. Your satisfaction is our priority.
                        </Typography>
                    </Grid>
                </Grid>

                {/* Why Choose CGOATS Section */}
                <Box sx={{ marginTop: '3rem' }}>
                    <Typography variant="h4" gutterBottom sx={{ color: '#fff', fontWeight: 600 }}>
                        Why Choose CGOATS?
                    </Typography>

                    <Grid container spacing={2} justifyContent="center">
                        {/* Transparent Delivery Model */}
                        <Grid item xs={12} sm={4}>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6 }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#222',
                                        padding: '2rem',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <AiOutlineTeam size={40} color="#ffeb3b" />
                                    <Typography variant="h6" sx={{ color: '#fff', marginTop: '1rem' }}>
                                        Transparent Delivery Model
                                    </Typography>
                                    <Typography variant="body2" color="white" sx={{ marginTop: '0.5rem' }}>
                                        From day one, you know what to expect and when.
                                    </Typography>
                                </Box>
                            </motion.div>
                        </Grid>

                        {/* Cutting-Edge Technologies */}
                        <Grid item xs={12} sm={4}>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6 }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#222',
                                        padding: '2rem',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FaLaptopCode size={40} color="#ffeb3b" />
                                    <Typography variant="h6" sx={{ color: '#fff', marginTop: '1rem' }}>
                                        Cutting-Edge Technologies
                                    </Typography>
                                    <Typography variant="body2" color="white" sx={{ marginTop: '0.5rem' }}>
                                        We use the latest tools and practices to deliver excellence.
                                    </Typography>
                                </Box>
                            </motion.div>
                        </Grid>

                        {/* Client-Centric Approach */}
                        <Grid item xs={12} sm={4}>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6 }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#222',
                                        padding: '2rem',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <AiOutlineAppstoreAdd size={40} color="#ffeb3b" />
                                    <Typography variant="h6" sx={{ color: '#fff', marginTop: '1rem' }}>
                                        Client-Centric Approach
                                    </Typography>
                                    <Typography variant="body2" color="white" sx={{ marginTop: '0.5rem' }}>
                                        Your goals are our top priority, ensuring satisfaction at every step.
                                    </Typography>
                                </Box>
                            </motion.div>
                        </Grid>
                    </Grid>

                    <Typography
                        variant="body1"
                        color="white"
                        sx={{
                            marginTop: '2rem',
                            fontStyle: 'italic',
                            textAlign: 'center',
                        }}
                    >
                        Our mission is simple: to provide solutions that are not just functional but also impactful. At CGOATS, we don’t just code – we "Goatify" every line to perfection!
                    </Typography>
                </Box>
            </motion.div>
        </Container>
    );
};

export default AboutUs;
