import React from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll'; // Import Link from react-scroll
import ShinyText from './Animations/ShinyText';
import Aurora from './Animations/Aurora';
import logo from '../assets/logo.svg';

const Header = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: -1, behavior: 'smooth' });
  };

  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: 'Black' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <div onClick={scrollToTop}>
            {/* <img src={logo} alt="Logo" style={{ height: '60px' }} onClick={scrollToTop} /> */}
            <ShinyText text="CGOATS" disabled={false} speed={1} className='custom-class' />
          </div>
          <div>
              <Button onClick={scrollToTop} color="inherit">Home</Button>
            <ScrollLink to="aboutUsSection" smooth={true} top={-2} duration={1000}>
              <Button color="inherit">About</Button>
            </ScrollLink>
            <ScrollLink to="contactUsSection" smooth={true} duration={1000}>
              <Button color="inherit">Contact Us</Button>
            </ScrollLink>
          </div>
        </Toolbar>
      </AppBar>
      <Box sx={{ marginTop: '65px' }}>
        <Aurora
          colorStops={["#3A29FF", "#FF94B4", "#FF3232"]}
          blend={0.5}
          amplitude={2.0}
          speed={0.5}
        />
      </Box>
    </>
  );
};

export default Header;
