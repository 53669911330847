import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Header from './components/Header';
import Footer from './components/Footer';
import Squares from './components/Animations/Squares';
import ClickSpark from './components/Animations/ClickSpark';
function App() {
    return (
        <Router>
            <div style={{ position: 'relative', width: '100%', minHeight: '100%' }}>
                <ClickSpark
                    sparkColor='#ffeb3b'
                    sparkSize={5}
                    sparkRadius={40}
                    sparkCount={8}
                    duration={500}
                    extraScale={0.5}
                >
                    <Header />
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}>
                        <Squares
                            speed={0.5}
                            squareSize={40}
                            direction='diagonal' // up, down, left, right, diagonal
                            borderColor='#353E43'
                            hoverFillColor='ffeb3b'
                        />
                    </div>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/about" element={<AboutUs />} />
                        <Route path="/contact" element={<ContactUs />} />
                    </Routes>
                    <Footer />
                </ClickSpark>

            </div>
        </Router>
    );
}

export default App;
